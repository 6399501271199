import { 
    Button, 
    FormControl, 
    MenuItem, 
    Select, 
    Typography, 
    useMediaQuery, 
    useTheme,
    Grid, 
    Container 
  } from '@mui/material';
  import React, { useState } from 'react';
  import Hidden from '@mui/material/Hidden';
  import ConfirmationDialog from '../../global/components/ConfirmationDialog';
  import { JigpawSinglePicker } from '../../global/components/JigpawPicker';
  import _ from 'lodash';
  import { jigpawConverter, Photobook, useBasket } from '../../models';
  import { makeStyles } from '@mui/styles';
  import { useProducts } from '../../API';
  import { Square } from '../../global/components/Square';
  import ProductPreview from '../../products/components/ProductPreview';
  import { Link as RouterLink, useParams, useHistory, useLocation } from 'react-router-dom';
  import { ImageEditorModal } from '../../products/components/ImageEditorModal';
  import { doc, getDoc } from 'firebase/firestore';
  import { db } from '../../firebase';
  import { useDocumentData } from 'react-firebase-hooks/firestore';
  import { DelayedCircularProgress } from '../../global/components/ProgressBars';
  import { CartButton, StyledP } from '../../styledComponents';
  


const useStyles = makeStyles((theme) => ({
    root: {
        // paddingTop: theme.spacing(8),
        // paddingBottom: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    actions: {
        // height: "98%",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(1),
        },
    },
    image: {
        padding: 0,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(1),
        },
    },
    sq: {
        backgroundColor: "transparent",
        color: "#413E40 !important",
        width: 100,
        height: 100,
        border: "2px solid #413E40",
        borderRadius: 8,
    },
    sqNotFirst: {
        marginLeft: theme.spacing(2),
    },
    filled: {
        borderColor: "#413E40",
        background: "#413E40",
        padding: "20px 60px 20px 60px",
    },
    dropdown: {
        color: "#413E40",
    },
    closeButton: {
        color: "#413E40",
    },
    menuPaper: {
        maxHeight: "25vh",
    },
    gridContainer: {
        backgroundColor: "#85a2ad",
    },
    productCategory: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    productCost: {
        color: "#413E40",
        fontWeight: 600,
    },
    menuItem: {
        color: "#413E40",
        transition: "background-color 0.3s ease",
        '&:hover': {
            backgroundColor: "blue",
            color: "white",
        },
    },
}));


const PhotobookFrontPage = ({user, photobook, currency}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {noSsr: true});

    const [productResponse ] = useProducts("photobooks", currency);
    const [basket ] = useBasket(user);
    const [pickerOpen, setPickerOpen] = useState(false);
    const [addToCartDialogOpen, setAddToCartDialogOpen] = useState(false);
    const [editorJigpaw, setEditorJigpaw] = useState();
    const [editorDialogOpen, setEditorDialogOpen] = useState(false);
    const [frontPageChecked, setFrontpageChecked] = useState(false);
    const [frontPageWarningDialogOpen, setFrontPageWarningDialogOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const {frontPageJigpawId} = useParams();
    const history = useHistory();
    const { state } = useLocation();

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
      };
    
      const handleMouseLeave = () => {
        setHoveredIndex(null);
      };
    
      const menuItemStyle = (index) => ({
        backgroundColor: hoveredIndex === index ? '#413E40' : 'transparent',
        color: hoveredIndex === index ? 'white' : 'inherit',
      });

    // console.log("state:", state)

    if (state) {
        const productId = _.get(state, "product")
        // console.log("product:", productId);
        if (productId) {
            photobook.updateProductId(productId, basket);
            history.replace({ state: {} })
        }
    }

    // const frontPage = _.get(photobook, "frontPage")
    const jigpawId = _.get(photobook.frontPage, "jigpawId", "missing");
    const oid = _.get(photobook.frontPage, "oid");
    const docId = (oid === 'system') ? user.uid + '_' + jigpawId : jigpawId;
    const [jigpaw ] = useDocumentData(doc(db, "jigpaws", docId).withConverter(jigpawConverter));

    // add in check for empty front page
    const isCoverBlank = () => {
    return !jigpaw; // If `jigpaw` is missing, the cover is blank.
    };

    const photobooks = _.get(productResponse, "products");

    const handleCloseJigpawPicker = (jigpaw) => {
        setPickerOpen(false);
        if (jigpaw) {
            photobook.updateFrontPage(jigpaw, basket);
            // history.replace({ pathname: `/products/photobook/${jigpaw.uuid}`})
        }
    };

    const updateProductId = (e) => {
        photobook.updateProductId(e.target.value, basket);
    };

    const addToCartConfirmed = () => {
        setAddToCartDialogOpen(false);
        basket.addPhotobook(photobook);
    };

    const AddToCartCancelled = () => {
        setAddToCartDialogOpen(false);
    };

    const [addToCartWarnings, setAddToCartWarnings] = useState([]);
// const [addToCartDialogOpen, setAddToCartDialogOpen] = useState(false);

// const addToCart = () => {
//     const warnings = [];
//
//     if (!photobook.isComplete()) {
//         warnings.push("Your photobook has empty pages.");
//     }
//
//     if (isCoverBlank()) {
//         warnings.push("Your photobook has a blank cover.");
//     }
//
//     if (warnings.length > 0) {
//         setAddToCartWarnings(warnings);
//         setAddToCartDialogOpen(true);
//     } else {
//         basket.addPhotobook(photobook);
//     }
// };
    const addToCart = () => {
    const warnings = [];

    // Check if photobook has empty pages (excluding the cover)
    if (!photobook.hasAllPages()) {
        warnings.push("Your photobook has empty pages.");
    }

    // Check if the cover is blank
    if (!photobook.hasFrontPage()) {
        warnings.push("Your photobook has a blank cover.");
    }

    if (warnings.length > 0) {
        setAddToCartWarnings(warnings);
        setAddToCartDialogOpen(true);
    } else {
        basket.addPhotobook(photobook);
    }
};

    const removeFromCart = () => {
        basket.removePhotobook();
    }

    const photobookInBasket = basket && basket.hasPhotobook();

    const disableAddToCart = () => {
        return false;
    };

    // console.log("photobooks", photobooks);

    const openEditor = async () => {
        const docRef = doc(db, "jigpaws", docId).withConverter(jigpawConverter);
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setEditorJigpaw(docSnap.data());
            setEditorDialogOpen(true)
        } else {

        }
    }

    const jigpawCreated = (jigpaw) => {
        setEditorDialogOpen(false)
        if (jigpaw) {
            photobook.updateFrontPage(jigpaw, basket);
            console.log("handleCloseJigpawPicker", jigpaw);
        }
    }

    const editorClosed = () => {
        setEditorDialogOpen(false)
    }

    const PhotobookFrontPageProduct = {
        category: "photobookFrontPage",
        id: "photobookFrontPage",
    }

    const changeFrontPageHandler = () => {
        console.log("change front page");
        setFrontPageWarningDialogOpen(false);
        updateFrontPage(frontPageJigpawId);
    };

    const useExistingFrontPageHandler = () => {
        console.log("use existing front page");
        setFrontPageWarningDialogOpen(false);
        history.replace({ pathname: `/products/photobook/${existingFrontPagejigpawId}`})
    };

    const existingFrontPagejigpawId = _.get(photobook.frontPage, "jigpawId");
    console.log("existingFrontPagejigpawId:", existingFrontPagejigpawId);

    const updateFrontPage = async (jigpawId) => {
        console.log("updateFrontPage: docid", docId)
        const docRef = doc(db, "jigpaws", docId).withConverter(jigpawConverter);
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            const jigpaw = docSnap.data()
            photobook.updateFrontPage(jigpaw, basket);

        }
    }

    if (!frontPageJigpawId) {
        console.log("checkss")
    } else if (user.isAnonymous && !frontPageChecked) {
        console.log("check 1")
        setFrontpageChecked(true);
        updateFrontPage(frontPageJigpawId);
    } else if (_.isNil(existingFrontPagejigpawId) && !frontPageChecked) {
        console.log("check 2")
        setFrontpageChecked(true);
        updateFrontPage(frontPageJigpawId);
    } else if (existingFrontPagejigpawId && !frontPageChecked && existingFrontPagejigpawId !== frontPageJigpawId) {
        console.log("check 3")
        console.log("existingFrontPagejigpawId", existingFrontPagejigpawId, "frontPageJigpawId", frontPageJigpawId, "frontPageChecked", frontPageChecked)
        setFrontpageChecked(true);
        setFrontPageWarningDialogOpen(true);
    }

    const backToProducts = () => {
        console.log("go back to products");
        history.goBack();
    }

    const jigpawUploaded = _.get(jigpaw, "stored.thumb") || _.get(jigpaw, "oid") === "system";



    return (

        <>
            <ConfirmationDialog
    message={addToCartWarnings.map((warning, index) => <p key={index}>{warning}</p>)}
    open={addToCartDialogOpen}
    handleCancel={AddToCartCancelled}
    handleConfirm={addToCartConfirmed}
    cancelText={"Cancel"}
    confirmText={"Yes"}
    className="custom-confirmation-dialog"
/>
            <ImageEditorModal open={editorDialogOpen} user={user} jigpaw={editorJigpaw} product={PhotobookFrontPageProduct}
                              jigpawCreated={jigpawCreated} onClose={editorClosed}/>
            <JigpawSinglePicker open={pickerOpen} onClose={handleCloseJigpawPicker} user={user}/>
            <Hidden mdDown>
                <Container className={"productsHome bgGray"} disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>Personalise your photobook</h1></div>
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>
                </Container></Hidden>
            <div className="spacer mobileNone"/>
            <Container className={classes.root} disableGutters={isSmallScreen} style={{maxWidth: "1280px"}}>
                <Grid container /*spacing={4}*/ className={`${classes.gridContainer} bgBlue productDetailContainer`} >
                    <Grid item xs={12} md={8}>
                        <Hidden mdUp>
                            <div className="spacerSmall"/>

                            <div><h1>Personalise your Photobook</h1></div>

                            <div className="productInfoDetail">

                                <p>Create edit your new pet photobook with our Jigpaws editing tool. Simply crop and resize your image Crop, change or add emojis to your photobook easily to and see how your pet appears on your brand-new gift.</p>
                            </div>
                            <div className="spacerMed"/>
                        </Hidden>
                        <Square>
                            <div onClick={() => {
                                setPickerOpen(true)
                            }} style={{"cursor": "pointer"}}>
                                {
                                    photobook.frontPage && jigpaw && photobooks && jigpawUploaded &&
                                    <ProductPreview user={user} jigpaw={jigpaw} productPreview={photobooks[0].preview}/>
                                }
                                {
                                    photobook.frontPage && jigpaw && photobooks && jigpawUploaded &&
                                    <DelayedCircularProgress/>
                                }
                                {
                                    !jigpaw &&
                                    <div className="productFirstLoadContainer">
                                        <div className="productFirstLoadOverlay"></div>
                                        <div className="productFirstLoadIcon">
                                            <img src={"/images/icons/addCoverOverlay.svg"} alt=""/>
                                        </div>
                                        <img src="/images/products/defaultProductImages/photobook.jpg"
                                             className={"jigpaw"} alt=""/>
                                    </div>
                                }
                            </div>
                        </Square>

                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Hidden mdDown>

                            <div className="productInfoTop">
                                <div><h1>Photobook</h1></div>
                                <div className="spacerMed"/>
                                <div className="productInfoDetail">
                                    <StyledP>Create edit your new pet photobook with our Jigpaws editing tool. Simply
                                        crop and resize your image Crop, change or add emojis to your photobook easily to
                                        and see how your pet appears on your brand-new gift!</StyledP>
                                </div>
                            </div>
                        </Hidden>
                        <>

                            <div className="productInfoMiddle">
                            <div className="productEdit">
                                <div className="spacerMed desktopNone"/>
                                <FormControl style={{color: "#413E40"}}>
                                        <div className="squareButtons" >
                                            <Button disabled={!jigpaw} style={{padding: "0px"}}
                                                className={classes.sq} variant="contained" size="large"
                                                    onClick={openEditor}><img src={"/images/icons/editImage.svg"}
                                                                              width={"41%"} height={"70%"}/></Button>
                                            <Button style={{padding: "0px"}} className={`${classes.sq} ${classes.sqNotFirst}`}variant="contained" size="large"
                                                    onClick={() => {
                                                        setPickerOpen(true)
                                                    }}><img src={"/images/icons/swapImage.svg"} width={"41%"}
                                                            height={"70%"}/></Button>
                                        </div>
                                    </FormControl>
                                <div className="spacerMed desktopNone"/>

                            </div></div></>
                        <div className="productInfobottom">
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                className={classes.actions}
                            >


                                {photobooks &&
                                <Select
                                    className={classes.dropdown}
                                    size="large"
                                    labelId="size-select-label"
                                    id="size-select"
                                    value={photobook.productId}
                                    // defaultValue=
                                    label="Size"
                                    onChange={updateProductId}
                                    MenuProps={{classes: {paper: classes.menuPaper}}}
                                >
                                    {_.map(Photobook.sizes, (size, index) => {
                                        return (
                                            <MenuItem 
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={handleMouseLeave}
                                            style={menuItemStyle(index)}
                                            value={size.productId} className={classes.menuItem}>
                                                {size.display}
                                            </MenuItem>
                                        );
                                    })}

                                    
                                                    
                                                    


                                </Select>

                                }
                                {photobooks &&
                                <Grid item xs={3} sm={3} md={6} alignItems="right" className="cartPriceContainer">
                                    <div className="productCostREDMAN">
                                        <div className="spacerMed mobileNone"/>
                            <div className="spacerSmall desktopNone"/>

                                        <Typography className={classes.productCost} variant="h3">{photobook.cost(photobooks, currency)}</Typography>
                                    </div>
                                </Grid>
                                }
                                <div>
                                    <div className="spacerMed mobileNone"/>
                            <div className="spacerSmall desktopNone"/>
                                    {photobookInBasket && !user.isAnonymous &&
                                    <CartButton variant="contained" size="large" onClick={removeFromCart}>Remove from
                                        cart</CartButton>
                                    }
                                    {!photobookInBasket && !user.isAnonymous &&
                                    <CartButton variant="contained" size="large" disabled={disableAddToCart()}
                                            onClick={addToCart}>Add to cart</CartButton>

                                    }
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className="productDetails">
                        <h2>Product Details</h2>
                        <div className="spacerSmall"/>
                        <div className="productInfoDetail" style={{'whiteSpace': 'pre'}}>
                            <StyledP>Available in 2 sizes:<br/>
            14 x 14 cm or 21 x 21 cm<br/>
            20 Customisable Pages Per Book<br/>
            2.5mm Hardcover with a Matte Laminate Finish<br/>
            Durable Library Binding<br/>
            170gsm Satin-Finished, Sustainably Sourced Paper
            </StyledP>
                        </div>
                    </div>
            </Container>
            <div className="spacer"/>
        </>
    )

}

export default PhotobookFrontPage;