import Grid from "@mui/material/Grid";
import {
    FormControl,
    Hidden,
    MenuItem,
    Select,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";


import {Link as RouterLink, useLocation, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import { app, auth } from "../../firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {doc, getFirestore } from "firebase/firestore";
import { useDocumentData} from "react-firebase-hooks/firestore";
import {useProducts} from "../../API";
import ProductPreview from "../components/ProductPreview";
import _ from "lodash";
import {jigpawConverter, useBasket} from "../../models";
import {JigpawSinglePicker} from "../../global/components/JigpawPicker";
import {useHistory} from "react-router-dom";
import Dinero from "dinero.js";
import Container from "@mui/material/Container";
import {Square} from "../../global/components/Square";
import {makeStyles} from "@mui/styles";
import {useLocale} from "../../api-locale";
import {jigpawsDineroFormatted} from "../../utils";
import ProductsGrid from "../../products/components/ProductsGrid";
import Helmet from "react-helmet";

import styled from "styled-components";

const defaultImageMap = {
    'greeting_card': '/images/products/defaultProductImages/card.jpg',
    'journal': '/images/products/defaultProductImages/notebook.jpg',
    'photo_tile': '/images/products/defaultProductImages/photoTile.jpg',
    'cushion': '/images/products/defaultProductImages/cushion.jpg',
    'jigsaw': '/images/products/defaultProductImages/jigsaw.jpg',
    'photobook': '/images/products/defaultProductImages/photobook.jpg'
}

const StyledP = styled.p`
font-size: 15px;

@media (min-width: 1280px) {
  font-size: 20px;
}
`;


const useStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    gridContainer: {
      backgroundColor: "#85a2ad",
    },
    productCategory: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    productCost: {
      color: "#413E40",
      fontWeight: 600,
    },
    actions: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    image: {
      padding: 0,
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    sq: {
      backgroundColor: "transparent",
      color: "#413E40 !important",
      width: 100,
      height: 100,
      border: "2px solid #413E40",
      borderRadius: 8,
    },
    sqNotFirst: {
      marginLeft: theme.spacing(2),
    },
    filled: {
      borderColor: "#413E40",
      background: "#413E40",
      padding: "20px 60px 20px 60px",
    },
    dropdown: {
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid black',
      },
      '& .MuiInput-underline:hover:before': {
        borderBottom: '2px solid black',
      },
      '& .MuiInput-underline:after': {
        borderBottom: '2px solid black',
      },
      color: "#413E40",
      paddingLeft: theme.spacing(1),
      maxHeight: "25vh",
    },
    closeButton: {
      color: "#413E40",
    },
  }));




const ProductVarientsDetail = ({user, jigpaw, products, initialVariantId, variants, currency}) => {
    //todo
    // const [basket, basketLoading, basketError] = useBasket(user);
    const [ basket ] = useBasket(user);
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();


    const [variant, setVariant] = useState(_.find(variants, {id: initialVariantId}))
    const [variantId, setVariantId] = useState(initialVariantId);
    const [quantity, setQuantity] = useState(1)

    const [open, setOpen] = React.useState(false);

    const [hoveredIndex, setHoveredIndex] = useState(null);


    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };
  
    const menuItemStyle = (index) => ({
      backgroundColor: hoveredIndex === index ? '#413E40' : 'transparent',
      color: hoveredIndex === index ? 'white' : 'inherit',
    });

    useEffect(()=>{
        const newQuantity = (basket && jigpaw && variantId) ? basket.itemQuantity(jigpaw.uuid, variantId) : 1;
        setQuantity(newQuantity)
        let v = _.find(variants, {id: variantId})
        if (!v) {
            v = variants[0]
            setVariantId(v.id)
        }
        setVariant(v)
    }, [basket, variantId, products])


    const showJigpawPicker = () => {
        setOpen(true);
    };

    const handleCloseJigpawPicker = (jigpaw) => {
        setOpen(false);
        if (jigpaw) {
            // console.log(jigpaw)
            history.push(`/product/${variant.category}/${jigpaw.linkUUID()}`);
        }
    };

    const addToCart = () => {
        basket.addItem(jigpaw, variant, quantity);
        // const preview = _.get(jigpaw, `previews.${variant.category}`)

        window.dataLayer.push({
            'event': 'add-to-cart',
            'item': {
                // item_id: variant.id,
                // item_name: variant.id,
                // quantity: quantity,
                // item_category: variant.category,
                itemId: variant.id,
                itemName: variant.id,
                quantity: quantity,
                itemCategory: variant.category,
                price: datalayerPrice,
                priceCurrency: 'GBP'
            }
        })
    }

    const updateQuantity = () => {
        basket.updateItem(jigpaw.uuid, variant.id, quantity);
    };

    // const handleClose = () => {
    //     history.goBack()
    // };

    if (!variant) {

        return null
    }

    const pathnameImageEditor = (jigpaw) ? `/product/${variant.id}/${jigpaw.linkUUID()}/image-editor` : '';
    const priceFormatted = jigpawsDineroFormatted(Dinero({amount: variant.price, currency: currency}));


    //Structured Data & data layer vars
    const priceDecimal= Math.floor(variant.price/100);
    const structuredPrice = priceDecimal.toFixed(2);
    const datalayerPrice = parseInt(priceDecimal.toFixed(2));
    let offersData;
    if (variant.displayCategory === 'JIGSAW') {
        //@TIM BARRY STRUCTURED DATA
    offersData = {
        "offers": [{
            "@type": "Offer",
            "name": "Personalised Jigsaw 500 pieces (50 x 38cm)",
            "price": "22.00",
            "priceCurrency": "GBP",
            "availability": "InStock"
        }, {
            "@type": "Offer",
            "name": "Personalised Jigsaw 1000 pieces (66 x 50cm)",
            "price": "34.00",
            "priceCurrency": "GBP",
            "availability": "InStock"
        }]
    }
    } else if (variant.displayCategory === 'CUSHION') {
    offersData = {
        "offers": [{
            "@type": "Offer",
            "name": "Personalised Cushion 30x30cm",
            "price": "24.00",
            "priceCurrency": "GBP",
            "availability": "InStock"
        }, {
            "@type": "Offer",
            "name": "Personalised Cushion 30x30cm",
            "price": "29.00",
            "priceCurrency": "GBP",
            "availability": "InStock"
        }]
    }
    } else if (variant.displayCategory === 'PHOTOBOOK') {
    offersData = {
        "offers": [{
            "@type": "Offer",
            "name": "Personalised Photobook 14x14cm",
            "price": "22.00",
            "priceCurrency": "GBP",
            "availability": "InStock"
        }, {
            "@type": "Offer",
            "name": "Personalised Photobook 21x21cm",
            "price": "24.00",
            "priceCurrency": "GBP",
            "availability": "InStock"
        }]
    }
    // } else if (variant.displayCategory === 'PHOTO TILE') {
    // offersData = {
    //     "offers": {
    //         "@type": "Offer",
    //         "name": "Personalised Photo-tile",
    //         "price": "19.00",
    //         "priceCurrency": "GBP",
    //         "availability": "InStock"
    //     }
    // }
     } else {
    offersData = {
        "offers": {
            '@type': 'Offer',
            name: 'Personalised ' + variant.name,
            price: structuredPrice,
            priceCurrency: 'GBP',
            availability: 'https://schema.org/InStock',
        }
    };
    }
     const structuredData = {
        '@context': 'http://schema.org',
            '@type': 'Product',
        image: 'https://jigpaws.com/:' + defaultImageMap[variant.category],
        description: variant.detailsLong,
        url: window.location.href,
         'category': variant.displayCategory,
         'name': 'Personalised ' + variant.displayCategory,
        'brand': {
        '@type': 'Thing',
        'name': 'Jigpaws'
      },
        'logo': "https://www.jigpaws.com/images/svg/jigpawsLogoWhite.jpg",
         offers: offersData.offers,
    }


    if (basket) {
        return <>
            <Helmet>
             {/* Add structured data using Helmet */}
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
            </Helmet>
            <Hidden mdDown>
                <Container className={"productsHome bgGray"} disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>Personalise your {variant.displayCategory} </h1></div>
                    {/*<div className="body">Edit your image with filters to make your pet really shine.</div>*/}
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>
                </Container></Hidden>
            <div className="spacer mobileNone"/>
            <Container className={classes.root} style={{ maxWidth: "1280px"}} disableGutters={isSmallScreen}>


                <JigpawSinglePicker open={open} onClose={handleCloseJigpawPicker} user={user}/>
                <Grid container /*spacing={4}*/ className={`${classes.gridContainer} bgBlue productDetailContainer`}>
                    <Grid item xs={12} md={8}>
                        <Hidden mdUp>

                            <div className="spacerSmall"/>
                            {/*<Typography className={classes.productCategory} variant="h1">{product.displayCategory}</Typography>*/}
                    <div><h1>Personalise your {variant.displayCategory}</h1></div>

                            <div className="productInfoDetail">
                            <p sx={{fontSize: "20px"}}>{variant.detailsLong}</p>
                            </div>
                            <div className="spacerMed"/>


                        </Hidden>
                        <Square>
                            <div onClick={showJigpawPicker} style={{"cursor": "pointer"}}>
                                {jigpaw &&
                                    <ProductPreview user={user} jigpaw={jigpaw}
                                                    productPreview={variant.preview} size={1600}></ProductPreview>
                                }
                                {!jigpaw &&
                                    <div className="productFirstLoadContainer">
                                        <div className="productFirstLoadOverlay"></div>
                                        <div className="productFirstLoadIcon">
                                            <img src={"/images/icons/swapImageOverlay.svg"} alt=""/>
                                        </div>
                                            <img src={defaultImageMap[variant.category]} className={"jigpaw"} alt=""/>
                                    </div>
                                }
                            </div>
                        </Square>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <>
                    <Hidden mdDown>

                                <div className="productInfoTop"><div><h2>{variant.displayCategory}</h2></div>
                                    <div className="spacerMed"/>
                                <div className="productInfoDetail">
                                <StyledP>{variant.detailsLong}</StyledP>
                                </div></div>
                    </Hidden>
                        </>
                        <div className="productInfoMiddle">
                        <div className="productEdit">
                                                                <div className="spacerMed desktopNone"/>

                                <FormControl style={{color: "#413E40"}}>
                                    <div className="squareButtons">
                                        <Button className={classes.sq}
                                                variant="contained"
                                                size="large"
                                                component={RouterLink}
                                                disabled={!jigpaw}
                                                to={{pathname: pathnameImageEditor}}>
                                            <img alt="edit" src={"/images/icons/editImage.svg"} width={"40%"} height={"40%"}/>
                                        </Button>

                                        <Button className={`${classes.sq} ${classes.sqNotFirst}`}
                                                variant="contained"
                                                size="large" onClick={showJigpawPicker}>
                                            <img alt="swap" src={"/images/icons/swapImage.svg"} width={"40%"} height={"40%"}/>
                                        </Button>
                                    </div>
                                </FormControl>
                                    </div>
                            </div>
                        <div className="productInfobottom">
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                className={classes.actions}
                            >

                                <Hidden mdDown>
                                    {/*<Typography variant="h2">{product.displayCategory}</Typography>*/}
                                    <div className="spacerMed mobileNone"/>
                                    <div className="spacerSmall desktopNone"/>
                                </Hidden>




                                <div className="spacerMed mobileNone"/>
                                <div className="spacerSmall desktopNone"/>


                                { variants.length > 1 && <>
                                    <div className="spacerMed mobileNone"/>
                                    <div className="spacerSmall desktopNone"/>
                                    <Select
                                        className={classes.dropdown}
                                        size="large"
                                        labelId="variant-select-label"
                                        id="variant-select"
                                        value={variantId}
                                        label="variant"
                                        onChange={(e) => setVariantId(e.target.value)}
                                        MenuProps={{classes: {paper: classes.menuPaper}}}
                                    >
                                        {_.map(variants, (v, index) => {
                                            return (
                                                <MenuItem 
                                                    value={v.id}
                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                    onMouseLeave={handleMouseLeave}
                                                    style={menuItemStyle(index)}
                                                    >
                                                    {v.displayVariant}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                                }
                                <div className="spacerMed mobileNone"/>
                                <div className="spacerSmall desktopNone"/>
                                <div className={"productQuantityCost"}>
                                <Select
                                    disabled={!jigpaw}
                                    className={classes.dropdown}
                                    size="large"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={quantity}
                                    label="quantity"
                                    onChange={(e) => setQuantity(e.target.value)}
                                    MenuProps={{classes: {paper: classes.menuPaper}}}
                                >
                                    {_.times(100, (num) => {
                                        num += 1
                                        return (
                                            <MenuItem value={num}>{num}</MenuItem>
                                        );
                                    })}
                                </Select>
                                    <div className={"productCost"}><Typography className={classes.productCost} variant="h3">{priceFormatted}</Typography></div>
                                </div>
                                <div className="spacerMed mobileNone"/>
                                <div className="spacerSmall desktopNone"/>

                                {
                                    (jigpaw && basket.hasItem(jigpaw.uuid, variant.id))
                                        ? <Button 
                                            variant="contained" 
                                            size="large"
                                            onClick={updateQuantity}
                                            sx={{padding: "20px 60px", fontSize: "20px", fontWeight: 400, lineHeight: "20px", letterSpacing: "0.05em"}}
                                            >Update
                                        </Button>
                                        : <Button
                                            variant="contained" 
                                            size="large" 
                                            disabled={!jigpaw}
                                            onClick={addToCart}
                                            sx={{padding: "20px 60px", fontSize: "20px", fontWeight: 400, lineHeight: "20px", letterSpacing: "0.05em"}}
                                            >
                                            Add to cart
                                        </Button>
                                }
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                <div className="productDetails">
                <h2>Product Details</h2>
                <div className="spacerSmall" />
                <div
                    className="productInfoDetail"
                    style={{
                    whiteSpace: 'pre',
                    fontWeight: 500,
                    fontSize: "20px",
                    }}
                >
                    {variant.details}
                </div>

                <style>
                    {`
                    @media (max-width: 1280px) {
                        .productInfoDetail {
                        font-size: 16px !important; /* Important to ensure it overrides other styles */
                        }
                        .productDetails h2 {
                        font-size: inherit; /* Ensure h2 font size remains unaffected */
                        }
                    }
                    `}
                </style>
                </div>
            </Container>
            <Container></Container>
            <div className="spacerMed"/>
            {jigpaw &&
                <>
                    <Container className="bgWhite" align="center" disableGutters={true}
                               maxWidth={false}>
                        <div className="spacer"/>
                        <div className="headerContainer">
                            <div><h2>Other Products</h2></div>
                            {/*<div className="body leader"><BalanceText>Inspirational text...</BalanceText></div>*/}
                        </div>
                        <div className="spacer"/>
                    </Container>
                    <div className="spacerMed"/>
                </>
            }
            <Container>
                <ProductsGrid products={products} jigpaw={jigpaw} user={user} currency={currency}></ProductsGrid>
            </Container>
            <div className="spacer"/>
        </>;
    }
    return (null);
}


const ProductVariantsDetailPageCurrency = ({user, currency}) => {
    const db = getFirestore(app);
    const {productId, jigpawId} = useParams();
    const {state} = useLocation();
    const [productResponse] = useProducts('all', currency);
    const ref = (jigpawId) ? doc(db, "jigpaws", jigpawId).withConverter(jigpawConverter) : null
    const [jigpaw ] = useDocumentData(
        ref,
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    if (!productResponse) {
        return null
    }
    // console.log("jipawId", jigpawId)
    // console.log("ref", ref)
    let products = productResponse.products;
    // console.log(productId);
    // console.log(productId.replace("-", "_"));
    // console.log("products", products)
    let variants = _.filter(products, {category: productId.replace("-", "_")})
    let productCategory = productId
    if (!variants) {
        // console.log("got here2")
        const product = _.find(productResponse.products, {'id': productId});
        productCategory = product.category
        variants = _.filter(products, {category: product.category})
    }

    const variantId = (state && state.variantId) ? state.variantId : variants[0].id;

    products = _.filter(products, (p)=> p.category !== productCategory)
    products = _.uniqBy(products, "category");
    products = _.sortBy(products, "display_order");

    // console.log("variants", variants)

    const jigpawUploaded = _.get(jigpaw, "stored.thumb") || _.get(jigpaw, "oid") === "system";
    if (jigpawId && !jigpawUploaded) {
        // return null;
        return (
            <>
            <div className="preloader">
                <img src="/images/svg/loader.gif" alt="loading"/>
                <div className="spacerMed"/>
                <p>Loading</p>
            </div></>
        )
    }

    if (variants) {
        return (
            <ProductVarientsDetail user={user} variants={variants} products={products} initialVariantId={variantId} jigpaw={jigpaw}
                                   currency={currency}></ProductVarientsDetail>
        );
    }
    return null;
}

const ProductVariantsDetailPageUser = ({user}) => {
    const {locale } = useLocale(user);

    if (locale) {
        return (
            <ProductVariantsDetailPageCurrency user={user}
                                               currency={locale.currencyCode}></ProductVariantsDetailPageCurrency>
        )
    }
    return (null);
}


const ProductsVariantsDetailPage = () => {
    const [user] = useAuthState(auth);
    if (user) {
        return (
            <ProductVariantsDetailPageUser user={user}></ProductVariantsDetailPageUser>
        );
    }
    return (null);
}

export default ProductsVariantsDetailPage

